import React, { useEffect } from "react";
import { graphql } from "gatsby";
import * as styles from "./../styles/modules/index.module.scss";

import Layout from "../components/layout";
import Head from "../components/head";
import PageNav from "../components/page-nav";
import { StaticImage } from "gatsby-plugin-image";

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  useEffect(() => {
    // eslint-disable-next-line
    if (document == undefined) return;

    const els = document.querySelectorAll("[data-animate]");

    if (!els.length) return;

    els.forEach(el => {
      const order = el.dataset.animate;

      el.style.transitionDelay = `${order * 0.2}s`;
      el.style.opacity = 1;
      el.style.transform = `none`;
    });

    const separator = document.querySelector("[data-animate-grow]");
    if (separator) {
      const width = separator.dataset.animateGrow;

      separator.style.width = width;
    }
  }, []);

  return (
    <Layout location={location} title={siteTitle}>
      <Head title="Home" />
      <section className={styles.banner}>
        <div>
          <h1 className={styles.bannerTitle} data-animate="1">
            Thinking. Results.
          </h1>
          <span
            className={styles.bannerSeparator}
            data-animate="4"
            data-animate-grow="5rem"
          ></span>
          <p data-animate="2" className={styles.bannerSubtitle}>
            Helping solve your important communication challenges
          </p>
        </div>
      </section>
      <PageNav />
      <section className={styles.homePageSection} id="about" data-animate="3">
        <div>
          <div className={styles.stickyContainer}>
            <h3>About</h3>
          </div>
          <p>
            Mannion Consulting brings proven expertise and commitment to resolving clients’ important communication challenges.
          </p>

          <StaticImage
            src="../images/robert-mannion.jpg"
            alt="Profile photo of Robert Mannion"
            className={styles.homePageImage}
          />
          <p>
            Formerly an award-winning journalist, founder{" "}
            <a
              href="https://www.linkedin.com/in/robert-mannion-4a55265/"
              target="_blank"
              rel="noreferrer"
            >
              Robert Mannion
            </a>{" "}
            advises leading private and public sector organisations. He has established a strong record for helping successfully manage crises, advance client opportunities and resolve critical issues though effective communication.
            <br />
            <br />
            Drawing on specialist practitioners as needed, he also provides expert media, writing and presentational training, along with valued support to leaders through communication challenges.
          </p>
        </div>
      </section>
      <section className={styles.homePageSection} id="work">
        <div>
          <div className={styles.stickyContainer}>
            <h3>Work</h3>
          </div>
          <h4>Expertise</h4>
          <ul>
            <li>Communication strategy and delivery</li>
            <li>Corporate communication, writing, documents, litigation support</li>
            <li>Crisis management and crisis communication response planning and preparation</li>
            <li>Executive communication coaching and materials, media and presentation training</li>
            <li>Communication to support change</li>
            <li>Stakeholder engagement strategy, audits</li>
            <li>Media relations</li>
            <li>Cyber incident response and planning</li>
          </ul>
          <h4>Sectors</h4>
          <ul>
            <li>Corporate, commercial and professional services</li>
            <li>Infrastructure and services</li>
            <li>Social agency, public good </li>
            <li>Health</li>
            <li>Primary and manufacturing</li>
            <li>Working alongside iwi-owned and Māori-focussed organisations</li>
            <li>Education and research</li>
            <li>Property, housing</li>
          </ul>
        </div>
      </section>

      <section className={styles.homePageSection} id="contact">
        <div>
          <div className={styles.stickyContainer}>
            <h3>Contact</h3>
          </div>
          <p className={styles.contactDetails}>
            Please call or email if you’d like to talk about a communication challenge or opportunity that is important to you.
            <br /><br />
            <a href="mailto:robert@mannionconsulting.co.nz">
              robert@mannionconsulting.co.nz
            </a>
            <a href="tel:+64272310301">272 310 301</a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
